import styled from "styled-components";

export const Area = styled.main` 
    background-image: url('/assets/images/backgroundFull.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100vh;

    .areaLogoHeader{
        display: none;
    }

    @media (max-width: 468px){
        height: 100%;
        background-image: ${props => props.active ? "url('/assets/images/bgMobile.png')" : "url('/assets/images/backgroundTijolos.png')"};

        .areaLogoHeader{
            display: block;
            text-align: center;
            width: 100%;
            transform: translate(1em, 2em);
        }
    }
`;

export const Title = styled.h1` 
    font-weight: 700;
    font-size: 3rem;
    line-height: 75px;
    letter-spacing: -0.0041em;
    color: #FFF6F6;
    margin: 0;
    text-align: center;

    @media (max-width: 468px){
        font-size: 26px;
        line-height: 30px;
        letter-spacing: -0.0041em;
        color: #FFF6F6;
        margin-bottom: 1em;
    }
`;

export const Info = styled.p` 
    margin: 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 56px;
    letter-spacing: -0.0041em;
    color: #FFFFFF;

    @media (max-width: 468px){
        display: none;
    }
`;

export const Card = styled.div` 
    background: #FFFFFF;
    border-radius: 12px;
    padding: 2em;
    width: 35em;
    position: relative;
    height: 80vh;
    overflow: auto;

    h1{
        font-weight: 700;
        font-size: 2rem;
        line-height: 47px;
        margin: 0;
        letter-spacing: -0.0041em;
        color: #E7393E;
        padding-bottom: .5em;
    }

    p{
        margin: 0;
        font-weight: 500;
        font-size: 1rem;
        line-height: 28px;
        letter-spacing: -0.0041em;
        color: #525252;
    }

    p:last-child{
        margin-bottom: .5em;
    }

    .areaLogo{
        transform: translate(-2.5em, -6.5em); 
        position: absolute;
        width: 100%;
        text-align: center;
    }

    .areaDownloads{
        display: none;
    }

    @media (max-width: 468px){
        width: 100%;
        box-shadow: 0px -10px 20px -5px rgba(0, 0, 0, 0.2);
        border-radius: 25px 25px 0 0;

        .areaLogo{
            display: none;
        }

        .areaQrcode{
            display: none;
        }

        .areaDownloads{
            margin-top: 1.5em;
            display: flex;

            img{
                cursor: pointer;
            }

            img:hover{
                opacity: .6;
            }
        }
    }
`;