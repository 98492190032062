import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        
        <Route path="*" element={<Home />} />

        <Route path="/politica-privacidade" element={<PoliticaPrivacidade />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
