import { CountDownCustom } from "./styled";
import Countdown from 'react-countdown';


export default function CountDown({ setState }){
    const renderer = ({ days, hours, minutes, seconds, completed, formatted }) => {
        if (completed) {
            setState(true);

            return (
                    <CountDownCustom>
                        <div className="AreaCount">
                            <p className="TextCount" style={{ padding: '1em' }}>
                                Aplicativo já está liberado
                            </p>
                        </div>
                    </CountDownCustom>
            )
        } else {
            return(
                <CountDownCustom>
                    <div className="AreaCount">
                        <div className="AreaValues">
                            <p>{formatted.days}</p>
                            <span>DIAS</span>
                        </div>
                        <div className="AreaDot">
                            <p>:</p>
                        </div>
                        <div className="AreaValues">
                            <p>{formatted.hours}</p>
                            <span>HORAS</span>
                        </div>
                        <div className="AreaDot">
                            <p>:</p>
                        </div>
                        <div className="AreaValues">
                            <p style={{ color: 'var(--main-color)' }}>{formatted.minutes}</p>
                            <span>MIN</span>
                        </div>
                        <div className="AreaDot">
                            <p>:</p>
                        </div>
                        <div className="AreaValues">
                            <p style={{ color: 'var(--main-color)' }}>{formatted.seconds}</p>
                            <span>SEG</span>
                        </div>
                    </div>
                </CountDownCustom>
            )
        }
      };
    
    return(
        <Countdown
            zeroPadTime={2}
            date={new Date("2022-08-02T11:00:00")}
            renderer={renderer}
        />
    )
}