import { Container, Grid, Divider, Grow } from "@mui/material";
import { useState } from "react";
import CountDown from "../../components/Countdown";
import { Area, Card, Info, Title } from "./styled";

export default function Home() {
  const [completedCountdown, setCompletedCountdown] = useState(false);

  return (
    <Area active={completedCountdown}>
      <div
        style={{
          background: completedCountdown
            ? "rgba(0, 0, 0, 0)"
            : "rgba(0, 0, 0, 0.45)",
          height: "100%",
        }}
      >
        <Container
          sx={{ height: "100%", minHeight: "100vh" }}
          disableGutters={true}
        >
          <Grid
            container
            spacing={6}
            sx={{ marginTop: 0, height: "100%", minHeight: "100vh" }}
          >
            <div className="areaLogoHeader">
              <img
                src="/assets/images/logoWhite.png"
                alt="Logo Plano&Plano branco"
              />
            </div>

            <Grid
              item
              md={6}
              sm={12}
              sx={{
                width: { md: "100%", xs: "85%" },
                margin: { md: 0, xs: "auto" },
              }}
              alignSelf="center"
            >
              {!completedCountdown && (
                <>
                  <Grow
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(true ? { timeout: 500 } : {})}
                  >
                    <Title>
                      Plano&Plano Cash Back
                      <br />
                      esta chegando!
                    </Title>
                  </Grow>

                  <Grow
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(true ? { timeout: 1500 } : {})}
                  >
                    <div>
                      <Info>Lançamento oficial em:</Info>

                      <CountDown setState={setCompletedCountdown} />
                    </div>
                  </Grow>
                </>
              )}
            </Grid>

            <Grid
              sx={{
                bottom: 0,
                position: {
                  md: "relative",
                  xs: completedCountdown ? "absolute" : "relative",
                },
              }}
              mt={4}
              item
              md={6}
              sm={12}
              alignSelf="center"
              alignItems="end"
              display="flex"
              flexDirection="column"
            >
              <Grow
                in={true}
                {...(true
                  ? { timeout: window.innerWidth > 468 ? 750 : 0 }
                  : {})}
              >
                <div>
                  <Card>
                    <div className="areaLogo">
                      <img
                        src="/assets/images/logoWhite.png"
                        alt="Logo Plano&Plano branco"
                      />
                    </div>

                    <h4>Como participar</h4>

                    <p>
                      1. Baixe o Cashback para Android (em breve para Iphone)
                    </p>

                    <div
                      className="areaDownloads"
                      style={{ justifyContent: "space-evenly" }}
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.adalov.planoeplanocashback"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/assets/images/googlePlay.png"
                          alt="Google Play"
                        />
                      </a>

                      <img
                        src="/assets/images/appleStore.png"
                        alt="Apple Store"
                      />
                    </div>

                    <div className="areaQrcode" style={{ textAlign: "center" }}>
                      <img
                        src="/assets/images/qrcode.png"
                        alt="Qr code baixar aplicativo"
                        width="150"
                        height="150"
                      />
                    </div>

                    <p>
                      <br />
                      <span>
                        se tiver Iphone acesse a versão web por aqui{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://web.planoeplanocashback.com.br/"
                        >
                          https://web.planoeplanocashback.com.br/
                        </a>
                      </span>
                    </p>

                    <Divider
                      sx={{ my: 2, borderColor: "#5656561f!important" }}
                    />

                    <p>
                      2. Se ainda não tiver, faça seu cadastro pelo aplicativo
                    </p>

                    <Divider
                      sx={{ my: 2, borderColor: "#5656561f!important" }}
                    />

                    <p>
                      {!completedCountdown
                        ? "3. Aguarde até o dia do lançamento para logar e aproveitar todas as vantagens!"
                        : "3. Aproveite todas as oportunidades do Cashback!"}
                    </p>
                  </Card>
                </div>
              </Grow>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Area>
  );
}
