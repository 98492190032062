import styled from "styled-components";

export const CountDownCustom = styled.div` 
    width: 100%;
    background: rgba(27, 27, 27, 0.75);
    border-radius: 25px;
    padding: 1em 0;
    outline: 5px solid #FFFFFF;
    border: 10px solid transparent;
    background-clip: padding-box;

    .AreaCount{
        display: flex;
    }

    .AreaValues{
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
    }

    p{
        font-weight: 700;
        font-size: 3rem;
        text-align: center;
        letter-spacing: -0.0041em;
        color: #FFFFFF;
        margin: 0;
    }

    span{
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.0041em;
        color: #FFFFFF;
        text-align: center;
    }

    @media (max-width: 468px){
        padding: .5em 0;

        p{
            font-size: 38px;
            line-height: 40px;
        }

        span{
            font-size: 14px;
            line-height: 17px;
        }
    }
`;