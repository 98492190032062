import { Container, Grid, Grow } from "@mui/material";
import { Area, Card } from "./styled";

export default function PoliticaPrivacidade(){
    return(
        <Area>
            <div style={{background: 'rgba(0, 0, 0, 0)', height: '100%' }}>
                <Container sx={{ height: '100%', minHeight: '100vh' }} disableGutters={true}>
                    <Grid container spacing={6} sx={{ marginTop: 0, height: '100%', minHeight: '100vh' }}>

                        <div className="areaLogoHeader">
                            <img src="/assets/images/logoWhite.png" alt="Logo Plano&Plano branco"/>
                        </div>

                        <Grid item md={6} sm={12} sx={{ width: {md: '100%', xs: '85%'}, margin: { md: 0, xs: 'auto' } }} alignSelf="center">


                        </Grid>

                        <Grid 
                        sx={{ bottom: 0, position: {md: "relative", xs: "absolute"} }}
                        mt={4} item md={6} sm={12} alignSelf="center" alignItems="end" display="flex" flexDirection="column">
                            <Grow
                                in={true}
                                style={{ transformOrigin: '0 0 0' }}
                                {...(true ? { timeout: window.innerWidth > 468 ? 1500 : 0} : {})}
                            >
                                <div>
                                    <Card>
                                        <div className="areaLogo">
                                            <img src="/assets/images/logoWhite.png" alt="Logo Plano&Plano branco"/>
                                        </div>

                                        <h1>Política de privacidade</h1>
                                        <p>
                                            Esta Política de Privacidade (“Política”) tem como intuito informar a você (“Usuário”), quais informações a seu respeito serão coletadas pela Plano&Plano, pessoa jurídica de direito privado, inscrita no Cadastro Nacional de Pessoas Jurídicas (“CNPJ/MF”) sob o n. 24.230.275/0001-80, com sede à Rua Samuel Morse, 74, CEP: 04576-060, Estado São Paulo (“Prestadora”), quando da realização do cadastro para participar do programa CashBack Plano&Plano (disponibilizado para as plataformas Android e iOS), bem como a forma pela qual tais informações são utilizadas e tratadas. 
                                        </p>
                                        <br/>
                                        <p>
                                            A presente Política poderá ser alterada a qualquer momento pela Prestadora, exceto em caso de vedação legal neste sentido. Assim, recomendamos a verificação periódica do teor desta Política pelo Usuário, tendo como base a data de atualização indicada ao término do documento. 
                                        </p>
                                        <h2>DEFINIÇÕES</h2>
                                        <p>
                                            <b>Dados pessoais</b> significa qualquer informação relativa a uma pessoa singular identificada ou identificável (titular dos dados); uma pessoa singular identificável é aquela que pode ser identificada, direta ou indiretamente, em particular por referência a um identificador, como um nome, um número de identificação, dados de localização, um identificador online ou a um ou mais fatores específicos da natureza física, fisiológica, identidade genética, mental, económica, cultural ou social dessa pessoa singular.  
                                        </p>
                                        <br/>
                                        <p>
                                            <b>Tratamento</b> significa qualquer operação ou conjunto de operações que são realizadas em dados pessoais ou em conjuntos de dados pessoais, seja por meios automatizados ou não, como coleta, registro, organização, estruturação, armazenamento, adaptação ou alteração, recuperação, consulta, uso, divulgação por transmissão, disseminação ou disponibilização de outra forma, alinhamento ou combinação, restrição, apagamento ou destruição.  
                                        </p>
                                        <br/>
                                        <p>
                                            <b>Controlador</b> significa a pessoa física ou jurídica, autoridade pública, agência ou outro órgão que, sozinho ou em conjunto com outros, determina as finalidades e os meios de processamento de dados pessoais.  
                                        </p>
                                        <br/>
                                        <p>
                                            <b>Operador</b> significa uma pessoa física ou jurídica, autoridade pública, agência ou outro órgão que processa dados pessoais em nome do controlador.  
                                        </p>
                                        <br/>
                                        <p>
                                            <b>Destinatário</b> significa uma pessoa física ou jurídica, autoridade pública, agência ou outro órgão, para o qual os dados pessoais são divulgados, incluindo processadores e controladores. 
                                        </p>
                                        <h2>DADOS COLETADOS E FINALIDADE</h2>
                                        <p>
                                            Por meio do aplicativo CashBack Plano&Plano, a Prestadora poderá coletar todas as informações ativamente fornecidas pelo Usuário, como seu nome, CPF/CNPJ, endereço, e-mail, telefone, entre outras informações solicitadas durante seu cadastro no aplicativo. 
                                        </p>
                                        <br/>
                                        <p>
                                            As informações do Corretor associado (usuário) são coletadas para comunicação e notificações, bem como para identificação, localização, indicação e vendas realizadas pelo associado. 
                                        </p>
                                        <br/>
                                        <p>
                                            Poderão, ainda, ser acessados dados de geolocalização, a fim de garantir que o usuário faça check-in e concorra a prêmios. 
                                        </p>
                                        <h2>COMPARTILHAMENTO DE DADOS</h2>
                                        <p>
                                            Os dados pessoais podem ser divulgados a prestadores de serviços que atuam em nosso nome para processar os dados pessoais de acordo com as finalidades descritas acima. Isso inclui as seguintes categorias de provedores de serviços: 
                                        </p>
                                        <br/>
                                        <p>
                                            Provedores de serviços de TI; Provedores de e-mail marketing; Provedores de serviços em nuvem; Fintechs. 
                                        </p>
                                        <br/>
                                        <p>
                                            O processamento e acesso de dados pessoais por prestadores de serviços depende das instruções do controlador de dados, conforme Art. 39 da LGPD. Assim, concluímos acordos de processamento de dados correspondentes com nossos respectivos provedores de serviços. O contrato obriga os prestadores de serviços a processar seus dados pessoais apenas em nosso nome e sob nossa instrução. Eles estão proibidos de transmitir seus dados pessoais a terceiros sem permissão, a menos que isso seja exigido por lei. Eles também são obrigados a adotar medidas administrativas, técnicas e organizacionais para proteger os dados pessoais de acesso não autorizado, de acordo com o artigo 46 da LGPD. 
                                        </p>
                                        <br/>
                                        <p>
                                            Não transferimos ou fornecemos acesso aos seus dados pessoais para destinatários de dados localizados em um país fora da República Federativa do Brasil. 
                                        </p>
                                        <h2>RETENÇÃO DE DADOS</h2>
                                        <p>
                                            Salvo disposição em contrário nesta política de privacidade, retemos seus dados pessoais apenas pelo tempo necessário para a respectiva finalidade, estendendo a retenção por 10 anos. Posteriormente, de acordo com o artigo 15 da LGPD, encerramos a atividade de processamento e excluímos seus dados pessoais, a menos que estejamos legalmente autorizados ou obrigados a mantê-los. 
                                        </p>
                                        <h2>SEGURANÇA DE DADOS</h2>
                                        <p>
                                            Em cumprimento ao artigo 46 da LGPD, implementamos medidas técnicas e organizacionais adequadas para garantir um nível de segurança adequado ao risco, levando em consideração o estado, os custos de implementação e a natureza, escopo, contexto e finalidades de tratamento, o risco de probabilidade e gravidade variável para os direitos e liberdades das pessoas físicas, bem como as diretrizes, regulamentos e requisitos aplicáveis ​​da ANPD. 
                                        </p>
                                        <h2>DIREITOS DO USUÁRIO</h2>
                                        <p>
                                            De acordo com o Artigo 18 da LGPD, você tem o direito de exercer todos os seguintes direitos (por si mesmo ou por um representante legalmente autorizado) sujeito a todas as exceções legais e circunstanciais: 
                                        </p>
                                        <br/>
                                        <p>
                                            1. Exigir (i) confirmação se seus dados pessoais estão sendo processados ​​por nós, (ii) acesso e/ou duplicatas de seus dados pessoais retidos por nós, (iii) receber os dados pessoais relativos a você, que você nos forneceu, em um formato estruturado, comumente usado e legível por máquina e (iv) transmissão de seus dados pessoais de nós para outro controlador; 
                                        </p>
                                        <br/>
                                        <p>
                                            2. Solicitar retificação, remoção, bloqueio, anonimização de seus dados pessoais; 
                                        </p>
                                        <br/>
                                        <p>
                                            3. Recusar-se a fornecer e – sem impacto nas atividades de processamento de dados que ocorreram antes de tal retirada – retirar seu consentimento para o processamento de seus dados pessoais a qualquer momento enquanto estiver informado sobre as consequências de negar ou retirar o consentimento; 
                                        </p>
                                        <br/>
                                        <p>
                                            4. Solicitar informações sobre as entidades públicas e privadas com as quais compartilhamos seus dados pessoais; 
                                        </p>
                                        <br/>
                                        <p>
                                            5. Opor-se ao processamento realizado por nós com base em uma das situações de dispensa de consentimento, caso haja descumprimento de alguma disposição da LGPD naquela atividade de processamento; 
                                        </p>
                                        <br/>
                                        <p>
                                            6. Solicitação de revisão de decisões tomadas exclusivamente por meio do processamento automatizado de dados pessoais, caso afetem seus interesses (incluindo decisões destinadas a definir seu perfil pessoal, profissional, consumidor e de crédito, ou aspectos de sua personalidade). 
                                        </p>
                                        <br/>
                                        <p>
                                            7. Tomar medidas legais em relação a qualquer potencial violação dos seus direitos relativos ao tratamento dos seus dados pessoais, bem como apresentar reclamações junto da ANPD. 
                                        </p>
                                        <br/>
                                        <p>
                                            Este aviso de privacidade foi atualizado pela última vez em 09 de agosto de 2022. 
                                        </p>
                                    </Card>
                                </div>
                            </Grow>
                        </Grid>

                    </Grid>
                </Container>
            </div>
        </Area>
    )
}